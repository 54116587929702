h4 {
  margin: 0 auto;
  width: 50%;
  text-align: center;
  margin-bottom: 1rem;
}

h4 a {
  color: black;
}

.message__container {
  height: 100%;
  width: max-content;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 1rem auto;
}
.message__container p {
  margin: 0.4rem auto;
}

.contact-form {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-evenly;
  width: 90%;
  margin: 0 auto;
  max-width: 1600px;
}
.contact-form label {
  width: 100%;
  margin: 0 auto;
}
.contact-form textarea {
  width: 100%;
}
.contact-form button[type=submit] {
  padding: 5px 20px;
}

@media screen and (min-width: 720px) {
  .contact-form {
    width: 80%;
  }
}

