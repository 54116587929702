.mobile-hidden {
  display: none;
}

.header__hr {
  margin: 0.25rem auto;
  width: 90%;
}

.header--primary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 1rem;
  width: 90%;
  margin: 0 auto;
}

.header__profile-image-container {
  display: none;
}

.header__profile-info {
  font-size: 0.8rem;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 0.55rem;
  font-family: Rockwell;
}
.header__profile-info a {
  color: black;
  text-decoration: none;
}

.menu-expand {
  margin-right: 1rem;
}

@media screen and (min-width: 720px) {
  .header__profile-info {
    font-size: 1rem;
  }
  .mobile-hidden {
    display: block;
  }
  .header--primary {
    justify-content: start;
    flex-direction: row-reverse;
    width: 90%;
    font-size: 1em;
    justify-content: space-between;
    margin: 0 auto;
  }
  .header__profile-image-container {
    background: linear-gradient(4deg, #333, transparent 85%);
    display: block;
    border-radius: 50%;
    height: 13vh;
  }
  .header__profile-image {
    border-radius: 50%;
    height: 100%;
  }
}

