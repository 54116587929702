.footer-wrapper {
  position: absolute;
  bottom: 0;
  height: 10vh;
  width: 100%;
}

.footer__hr {
  margin: 0.5vh auto 1.75vh auto;
  width: 90%;
}

.footer--primary {
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  width: 90%;
  margin: 0.25rem auto;
}

.footer__section {
  display: flex;
  width: 95%;
  font-size: 0.8rem;
  font-weight: 700;
  margin: 0.5rem auto;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
}
.footer__section a {
  color: black;
  text-decoration: none;
  display: flex;
  align-items: center;
  text-transform: uppercase;
}
.footer__section div {
  display: flex;
  align-items: center;
}
.footer__section:nth-child(2) {
  display: none;
}

.footer__icon {
  width: 25px;
  margin: 5px;
}

@media screen and (min-width: 720px) {
  .footer--primary {
    flex-direction: row;
  }
  .footer__section {
    display: flex;
    width: 30%;
    justify-content: center;
    align-items: center;
  }
  .footer__section div {
    display: flex;
    align-items: center;
  }
  .footer__section:nth-child(1) {
    justify-content: flex-start;
  }
  .footer__section:nth-child(2) {
    display: flex;
  }
  .footer__section:nth-child(3) {
    justify-content: flex-end;
  }
}

