* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
}

.App {
  margin: 0 auto;
}

main, footer {
  width: 90%;
  margin: 0.25rem auto;
  font-family: Kameron;
  font-weight: 600;
}

button {
  font-family: Kameron;
}

.main__heading {
  font-family: Kameron;
  text-align: center;
  font-size: 1.25rem;
  margin-bottom: 1.5rem;
}

.main__heading__horizontal-rule {
  width: 50%;
  margin: 0.5rem auto;
}

@media screen and (min-width: 720px) {
  .main__heading {
    margin-top: 3rem;
    font-size: 1.75rem;
  }
}

