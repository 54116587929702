.pdf-preview {
    width: max-content;
    height: max-content;
    margin: 1.2rem auto;
    border: 2px solid black;
}

.error-notice {
    color: maroon;
    text-align: center;
    margin: .5rem auto;
}

.recommendations-section {
    width: 90%;
    margin: 0 auto;

    blockquote {
        font-size: .85rem;
    }

    .quote-source {
        padding: .5rem;
        display: inline-block;
        width: 100%;
        text-align: right;
    }
}

.buttons-container {
    display: flex;
    width: max-content;
    margin: 0 auto;

    button {
        cursor: pointer;
        min-width: 130px;
        padding: 8px;
        font-size: .8rem;
        margin: 1rem;
        border-radius: 20px;
        border: 0px;
        background-color: slategrey;
        color: white;
    }
}

@media screen and (min-width: 720px) {
    .buttons-container {
        button {
            min-width: 150px;
        }
    }

}