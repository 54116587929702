.project-section {
    padding: 1rem;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    font-family: Rockwell;
    margin-top: 5rem;
    padding-bottom: 2rem;
    border: 2px solid #aaa;
    border-radius: 2px;
    background: rgba(250, 250, 240, .5);
}

.project-section:nth-child(3) {
    margin-top: 2.5rem;
}

.project-title {
    align-self: start;
    margin-bottom: .5rem;
    font-size: .9rem;
}


.project-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 1em;
    margin: 1rem 0;
    font-size: .75rem;

    h4 {
        margin: 0;
        text-align: left;
        width: max-content;
        border-bottom: 2px solid black;
        font-size: .9rem;
    }

    ul {
        margin-top: 3px;
        list-style: none;
        font-size: .7rem;
        display: flex;
        // height: 6rem;
        width: 100%;
        flex-wrap: wrap;
        justify-content: start;
    }

    li {
        flex-basis: auto;
        padding: .35rem 2.5%;
    }
}

.project-image {
    position: relative;
    transition: all .6s;

    a {
        top: calc(50% - 10px);
        left: 25%;
        position: absolute;
        margin: 0 auto;
        padding: 2px 5px;
        width: 50%;
        height: 20px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
    }

    img {
        width: 100%;
        margin: 0 auto;
    }

    .mobile-image-prvw {
        width: 25%;
        height: auto;
        transform: translateY(20%);
        right: 2%;
        position: absolute;
    }

}

.project-image__button {
    border: none;
    padding: 10px 15px;
    font-family: Rockwell;
    font-weight: 700;
    color: white;
    border-radius: 5px;
    box-shadow: 0px 0px 5px 3px white;
    cursor: pointer;
    transition: all .6s;
    text-shadow: 1px 1px black;
    background-color: rgb(0, 100, 0);
    border: 2px solid black;

    &:hover{
        color: black;
        background-color: white;
    }
}

.project-description {
    font-size: .75rem;
}
.videos__container {
    grid-area: vid;
    padding: 1rem 0;
    margin-top: 1rem;

    h4 {
        margin: 0;
        text-align: left;
        width: max-content;
        border-bottom: 2px solid black;
        font-size: .9rem;
    }

    .video-links__container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        align-items: center;
        margin: .75rem auto;

        .video-link__container {
            width: 45%;
            cursor: pointer;

            .thumbnail__container {
                position: relative;

                .play-icon__container {
                    position: absolute;
                    top: 0;
                    left: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 100%;

                    .play-icon {
                        z-index: 2;
                        background-color: white;
                        border-radius: 50%;
                    }
                }
            }

            img {
                width: 100%;
                border: 1px solid black;
            }

            p {
                font-size: .8rem;
                text-align: center;
            }
        }
    }
}

.video-popup {
    position: fixed;
    width: 100vw;
    left: 0;
    padding: 10px;
    border: 2px solid black;
    height: 100vh;
    top: 0vh;
    z-index: 3;
    background-color: rgba(255, 255, 255, .85);
    display: flex;
    justify-content: center;
    align-items: center;

    &.modal-visible {
        display: flex;
    }

    &.modal-hidden {
        display: none;
    }

    .close-button {
        position: absolute;
        top: 15px;
        right: 2rem;
    }

    video {
        width: 90%;
        height: auto;
    }

}


@media screen and (min-width: 720px) {

    .project-section {
        display: grid;
        // grid-template: repeat(3, minmax(1px, 1fr)) / repeat(5, 1fr);
        grid-template: repeat(3, max-content) / repeat(5, 1fr);
        gap: 0 1rem;
    }

    .image-right {
        grid-template-areas:
            'title title image image image'
            'description description image image image'
            'vid vid image image image'
            'details details image image image';
    }

    .image-left {
        grid-template-areas:
            'image image image title title'
            'image image image description description'
            'image image image vid vid'
            'image image image details details';
    }

    .project-title {
        grid-area: title;

        hr {
            width: 35%;
            margin: 1rem;
        }
    }

    .project-image {
        grid-area: image;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .project-image__button {
        font-size: 1.25rem;
    }

    .project-details {
        align-items: start;
        grid-area: details;
        margin: 0;
    }

    .videos__container {
        margin-bottom: 2rem
    };
    
    .project-description {
        grid-area: description;
        font-size: .9rem;
        margin-bottom: 2rem;
    }
    
    video {
        height: 80%;
        width: auto;
    }
    
}