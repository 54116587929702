.gallery-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  max-width: 100%;
}

.personal-page__heading--secondary {
  font-weight: 400;
  font-size: 0.75rem;
  text-align: center;
}

@media screen and (min-width: 720px) {
  .personal-page__heading--secondary {
    font-size: 0.9rem;
  }
  .gallery-container {
    display: grid;
    grid-template: repeat(3, 1fr)/repeat(3, 1fr);
    gap: 8px;
  }
}

