.gallery-link :hover {
  scale: 1.02;
}
.gallery-link .gallery-image {
  width: 100%;
  min-width: 90vw;
  max-height: 100%;
  aspect-ratio: 4/3;
  object-fit: cover;
  object-position: center;
  margin-top: 10px;
}

@media screen and (min-width: 720px) {
  .gallery-link .gallery-image {
    min-width: 0;
  }
}

