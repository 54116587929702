main {
  font-size: 0.8rem;
}

.access-overlay {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.access-overlay p {
  text-align: center;
  margin: 1rem auto;
}
.access-overlay .inputs-div form {
  margin: 0 auto;
  width: max-content;
  display: flex;
  flex-direction: column;
}
.access-overlay .inputs-div input[type=text] {
  padding: 3px;
}
.access-overlay .inputs-div button {
  margin-top: 1rem;
  padding: 3px;
  background-color: #444;
  color: white;
  font-weight: 500;
  font-size: 1rem;
  border-radius: 1px;
  border: 0px;
  cursor: pointer;
}
.access-overlay .inputs-div button:active {
  background-color: white;
  color: black;
  border: 2px solid #444;
}

.profile-image {
  width: 70%;
  padding: 15px;
  float: right;
}

.about-paragraph {
  padding: 5px;
}

.about--summary .tech-groups {
  margin: 10px auto;
  display: grid;
  grid-template: auto/repeat(4, 1fr);
  width: max-content;
}
.about--summary .tech-group {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 1rem auto;
}
.about--summary .tech-group h6 {
  font-size: 0.75rem;
  font-weight: 500;
}
.about--summary .tech-group img {
  height: 2.5rem;
}
.about--summary .tech-group:hover {
  transform: scale(1.2);
}

.first-letter {
  font-size: 2rem;
}

details {
  padding: 5px;
  clear: both;
}

@media screen and (min-width: 720px) {
  main {
    font-size: 1rem;
    line-height: 120%;
  }
  .profile-image {
    width: 50%;
  }
  .about--summary .tech-groups {
    grid-template: auto/repeat(6, 1fr);
  }
  .tech-group img {
    height: 3.5rem;
  }
}

